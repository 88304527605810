'use strict';

(function (factory) {
    factory(jQuery);
}(function ($) {

    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    $.extend($.validator.messages, {
        'invalid': 'Bitte gib gültige Daten ein',
        'legalDate': 'Du musst mindestens 18 Jahre alt sein, um im Jack Wolfskin Shop bestellen zu können.',
        'maxlength': $.validator.format('Bitte gib maximal {0} Zeichen ein.'),
        'minlength': $.validator.format('Bitte gib mindestens {0} Zeichen ein.'),
        'rangelength': $.validator.format('Bitte gib mindestens {0} und maximal {1} Zeichen ein.'),
        'email': 'Bitte gib eine gültige E-Mail-Adresse ein.',
        'url': 'Bitte gib eine gültige URL ein.',
        'date': 'Bitte gib ein gültiges Datum ein.',
        'number': 'Bitte gib eine Nummer ein.',
        'digits': 'Bitte gib nur Ziffern ein.',
        'equalTo': 'Bitte denselben Wert wiederholen.',
        'range': $.validator.format('Bitte gib einen Wert zwischen {0} und {1} ein.'),
        'max': $.validator.format('Bitte gib einen Wert kleiner oder gleich {0} ein.'),
        'min': $.validator.format('Bitte gib einen Wert größer oder gleich {0} ein.'),
        'creditcard': 'Bitte gib eine gültige Kreditkarten-Nummer ein.',
        'invalidZip': 'Bitte gib eine gültige Postleitzahl ein',
        'couponCodeMistakenForGiftCard': 'Bitte gib einen gültigen Gutschein- oder Aktionscode ein. Falls du eine Geschenkkarte hast, kannst du diese im Checkout einlösen.',
        'invalidGiftCard': 'Bitte gib eine Geschenkkartennummer ein. Falls du einen Gutschein- oder Aktionscode hast, kannst du diesen im Warenkorb einlösen.',
        'streetMissingHouseNumber': 'Du hast keine Hausnummer angegeben, ist das korrekt?',
        'invalidPassword': 'Das Passwort muss min. ein Sonderzeichen, einen Großbuchstaben und eine Zahl enthalten und min. 8 Zeichen lang sein.',
        'passwordsDontMatch': 'Die Passwörter stimmen nicht überein.',
        'selectReturnReason': 'Bitte wähle einen Retourengrund aus, um fortzufahren'
    });
    return $;
}));
